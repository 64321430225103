.desktopDrawer .desktopDrawerPaper {
  width: 256px;
  top: 4rem;
  height: calc(100% - 4rem);
}

.mobileDrawer {
  width: 256px;
}

.logo {
  width: 8rem;
}