@import "src/assets/breakpoints";

.root {
  background-color: var(--color-gray);
  padding: 200px 0;

  @include md-max {
    padding: 60px 0;
  }
}

.image {
  width: 100%;
  max-width: 20rem;
  margin: 0 auto;
  padding: 2rem 0 5rem 4rem;
  @include md-max {
    padding: 4rem 1rem;
  }
}