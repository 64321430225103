$screen-xs: 377px;
$screen-sm: 600px;
$screen-md: 900px;
$screen-lg: 1200px;
$screen-xl: 1536px;

@mixin sm-max {
  @media (max-width: #{$screen-sm}) {
    @content;
  }
}

@mixin md-max {
  @media (max-width: #{$screen-md}) {
    @content;
  }
}