.root {
  z-index: 9999;
}

.toolbar {
  min-height: 4rem;
}

.logo {
  height: 2.5rem;
  width: auto;

}