$primary-color: #9c5239;
$secondary-color: #f7f2ee;
$tertriary-color: #414141;
$gray: #f4f6f8;
$success: #66bb6a;
$error: #f44336;
$lightgray: #706966;



:root {
  --color-primary: #{$primary-color};
  --color-secondary: #{$secondary-color};
  --color-tertriary: #{$tertriary-color};
  --color-gray: #{$gray};
  --color-success: #{$success};
  --color-error: #{$error};
  --color-light-gray: #{$lightgray};
}